import * as React from 'react';
import { Link } from 'gatsby';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import theme from '../../design-system/theme';
import SvgBackArrow from '../../design-system/icons/js/BackArrow';

export default class TextPageContent extends React.PureComponent {
	render() {
		const { html } = this.props;
		return (
			<StyledTextPageContent>
				<header>
					<Link to="/">
						<div>
							<SvgBackArrow />
						</div>
					</Link>
				</header>
				<div className="content" dangerouslySetInnerHTML={{ __html: html }} />
			</StyledTextPageContent>
		);
	}
}

TextPageContent.propTypes = {
	html: PropTypes.string.isRequired
};

const StyledTextPageContent = styled.div`
	> header {
		box-sizing: border-box;
		max-width: ${theme.dimensions.contentWidthSmall};
		padding: 27px 0 12px 0;
		margin: 0 auto 20px auto;
		border-bottom: 1px solid ${theme.colors.line};

		@media (min-width: ${theme.breakpoints.tablet}px) {
			max-width: ${theme.dimensions.contentWidthLarge};
		}

		> a {
			display: block;
			font-size: 0;

			> div {
				display: inline-block;
				position: relative;

				&::before {
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					width: 40px;
					height: 40px;
					background-color: rgba(255, 0, 0, 0);
					transform: translate(-50%, -50%);
				}
			}

			svg {
				display: block;
				width: 24px;
				height: 24px;
				margin-left: -3px;
				color: ${theme.colors.icon};
			}
		}
	}

	> .content {
		max-width: ${theme.dimensions.contentWidthSmall};
		margin: 0 auto;
		text-align: left;

		@media (min-width: ${theme.breakpoints.tablet}px) {
			max-width: ${theme.dimensions.contentWidthLarge};
			font-size: inherit;
		}

		h1 {
			font-size: inherit;
		}

		h2 {
			font-size: inherit;
		}

		p {
			margin: 10px 0;
		}
	}
`;
