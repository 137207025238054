import * as React from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import SEO from '../seo';
import Layout from '../Layout';
import TextPageContent from './TextPageContent';
import BackToTopLink from '../BackToTopLink';

export default class CasePage extends React.PureComponent {
	render() {
		const { html, content, commonContent } = this.props;
		return (
			<StyledCasePage>
				<SEO title={`TNR / ${content.title}`} />
				<TextPageContent html={html} />
				<BackToTopLink className="back-to-top" label={commonContent.backToTop} />
			</StyledCasePage>
		);
	}
}

CasePage.propTypes = {
	html: PropTypes.string.isRequired,
	content: PropTypes.shape({
		title: PropTypes.string.isRequired
	}).isRequired,
	commonContent: PropTypes.shape({
		backToTop: PropTypes.string.isRequired
	}).isRequired
};

const StyledCasePage = styled(Layout)`
	padding-bottom: 100px;

	.back-to-top {
		margin-top: 40px;
	}
`;
