import * as React from 'react';
import { graphql } from 'gatsby';
import TextPage from '../components/text-page/TextPage';

const DataProtectionPolicyContainer = props => {
	const {
		data: {
			pageContent: {
				childMarkdownRemark: { html, frontmatter }
			},
			commonContent: {
				childMarkdownRemark: { frontmatter: commonFrontmatter }
			}
		}
	} = props;
	return <TextPage html={html} content={frontmatter} commonContent={commonFrontmatter} />;
};

export const pageQuery = graphql`
	query DataProtectionPolicyPageQuery {
		pageContent: file(sourceInstanceName: { eq: "pages" }, name: { eq: "data-protection-policy" }) {
			childMarkdownRemark {
				html
				frontmatter {
					title
				}
			}
		}
		commonContent: file(sourceInstanceName: { eq: "pages" }, name: { eq: "common" }) {
			childMarkdownRemark {
				frontmatter {
					backToTop
				}
			}
		}
	}
`;

export default DataProtectionPolicyContainer;
